.controls button {
  border: 0;
  background: transparent;
  width: 40px;
  height: 40px;
}
.controls__playpause {
  color: var(--primary);
}

.controls__stop {
  color: var(--primary-dark);
  border: 1px solid white;
}

.controls__iconstroke {
  stroke: white; /* Replace with your desired stroke color */
  stroke-width: 0.5px; /* Replace with your desired stroke width */
}
