.workspace {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

.workspace__main {
  display: flex;
  height: 100%;
}

.workspace__left {
  position: relative;
  width: 100%;
}

.workspace__canvas {
  width: 100%;
  height: 100%;

  /* account for disclaimer bar */
  /* padding-top: 26px; */
}

.workspace__right {
  width: 500px;
  overflow: hidden;
  margin-top: 2rem;
  margin-bottom: 1rem;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  box-shadow: var(--shadow-depth-4);
}

.workspace__sidebar {
  display: flex;
  background: var(--white);
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  position: relative;
  box-shadow: var(--shadow-depth-2);
}
.workspace__tabs {
  --tab-width: 100px;
  display: flex;
  flex-direction: column;
  width: var(--tab-width);
  height: 100%;
  padding: 0;
  border-left: 1px solid var(--gray-100);
}
.workspace__tabs button {
  position: relative;
  border-bottom: 1px solid var(--gray-100);
  width: 100%;
  height: calc(var(--tab-width) / 1.2);
  color: var(--slate-400);
}
.workspace__tabs button.active {
  color: var(--primary);
}
.workspace__tabs button.active::after {
  content: '';
  position: absolute;
  top: 0;
  left: -1px;
  width: 1px;
  height: 100%;
  background-color: var(--primary);
}
.workspace__tabs button span {
  display: block;
  font-size: 0.6rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 0.3rem;
}

.workspace__timeline {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 150px;
  max-height: 80%;
  min-height: 50px;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 10;
}

.timeline__resize-handle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  z-index: 10;
  background-color: transparent;
  transition: background-color 300ms ease-in-out;
}
.timeline__resize-handle:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 8px;
  background-color: transparent;
  transform: translateY(-50%);
}
.timeline__resize-handle:hover,
.timeline__resize-handle:active {
  background-color: var(--primary);
}
.timeline__resize-notch {
  position: absolute;
  top: 0;
  left: 50%;
  width: 1px;
  height: 100%;
  transform: translateX(-50%);
}

.workspace__timeline.loading {
  pointer-events: none;
}

.workspace__timeline.loading > div {
  opacity: 0.3;
}

.workspace__timeline.loading:before {
  content: 'Updating timeline...';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2rem;
  color: var(--black);
}

.workspace__toolbar {
  border-top: 1px solid var(--gray-100);
  background-color: var(--white);
  display: flex;
  justify-content: flex-end;
  padding: 0 0.5rem;
}

.workspace__scrub {
  position: relative;
  padding-left: 5px;
}

.workspace__tracks {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  border-top: 1px solid var(--gray-100);
  background-color: var(--white);
}

.workspace__controls {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -4rem;
}

.workspace__controls .scrub {
  flex: 1;
}
