.tracks-container {
  position: relative;
  width: 100%;
  border-bottom: 1px solid var(--slate-50);
}

.track {
  position: relative;
  height: var(--timeline-track-height);
  width: 500%;
}

.track__header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: var(--timeline-track-header-width);
  height: 100%;
  padding: 0.5rem;
  padding-top: 0.3rem;
  position: sticky;
  left: 0;
  background: var(--white);
  z-index: var(--z-index-timeline-track-header);
  border: solid var(--slate-50);
  border-width: 1px 2px;
}

.track__title {
  display: flex;
  align-items: center;
  font-family: var(--font-family-headings);
}

.track__title h3 {
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 0;
  position: relative;
  top: 2px;
  left: 4px;
}

.track__actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.3rem;
}

.track__actions .btn-link {
  padding: 0;
  color: var(--slate-700);
}

.track__actions .btn-link:hover {
  color: var(--primary);
  background-color: transparent;
}

.track__buttons {
  display: flex;
  gap: 10px;
}

.track__drag-handle svg {
  color: var(--slate-300);
  pointer-events: none;
}

.track__clips {
  position: absolute;
  /* left: var(--timeline-track-left); */
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.track__clips::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: -6px;
  width: 6px;
  background-color: var(--slate-50);
  z-index: var(--z-index-timeline-track-clips);
}

.track.dropzone-active {
  background-color: var(--slate-50);
}

.track.dropzone-active::before,
.track.dropzone-active::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 3px;
  background-color: var(--primary);
  z-index: var(--z-index-timeline-track-dropzone-active);
}
.track.dropzone-active::before,
.track.dropzone-active .track__header::before {
  top: 0;
}
.track.dropzone-active::after,
.track.dropzone-active .track__header::after {
  bottom: 0;
}

.track-clone {
  position: absolute;
  box-shadow: 0 0 0 2px var(--slate-100);
  opacity: 0.9;
  background-color: var(--slate-50);
  z-index: var(--z-index-timeline-track-clone);
}

.track-placeholder > * {
  visibility: hidden;
}

.track__subtracks {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: var(--timeline-track-width);
  width: 100%;
  height: 100%;
  border-bottom: 1px solid var(--slate-50);
}

.track__subtrack--mask {
  flex-basis: var(--timeline-subtrack-mask-height);
  border-bottom: 1px solid var(--slate-50);
  background-color: var(--slate-50);
}
.subtrack__clips {
  flex-basis: 46px;
}
