.timeline-playhead {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  z-index: var(--z-index-timeline-header-playhead);
}

.timeline-playhead__icon {
  width: 1.25rem;
  height: 1.25rem;
  transform: rotate(180deg) translateX(calc(50% - 1.5px));
}

.timeline-playhead__icon::after {
  position: absolute;
  top: 0;
  left: 50%;
  height: var(--timeline-header-height);
  transform: translate(-50%, -50%);
  content: '';
  width: 3px;
  background: red;
}

.timeline-playhead__icon svg {
  width: 100%;
  height: 100%;
  fill: red;
}
