.color-picker {
  position: relative;
}
.color-picker input {
  width: 100%;
  padding: 0.25rem;
}
.color-picker input::-webkit-color-swatch-wrapper {
  padding: 0;
}
.color-picker.isTransparent input::-webkit-color-swatch {
  background-color: white !important;
}
.color-picker.isTransparent input::-moz-color-swatch {
  background-color: white !important;
}
.color-picker.isTransparent::after {
  content: '';
  width: 100%;
  position: absolute;
  right: 0;
  top: 50%;
  border-bottom: 2px solid red;
  transform: skewY(-11deg);
}
