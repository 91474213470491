.canvas {
  width: 100%;
  height: 100%;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
}

canvas {
  margin: 0 !important;
  padding: 0 !important;
}

.canvas__inner {
  display: flex;
  flex-direction: column;
  position: relative;
}

.canvas canvas {
  margin: 0 auto;
  transition: all 300ms ease-in-out;
}

.canvas-disclaimer {
  width: 100%;
  text-align: center;
  font-size: 0.8rem;
  font-weight: bold;
  color: white;
  background: var(--slate-300);
  padding: 0.2rem;
  pointer-events: none;
  margin-bottom: 0;

  position: absolute;
  bottom: -25px;
  left: 0;
  z-index: 10;
}
