.clip {
  position: absolute;
  z-index: var(--z-index-timeline-clip);
  border-radius: 3px;
  min-width: 5px;

  container-name: clip-container;
  container-type: inline-size;
}

.clip::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-left: 2px solid rgba(255, 255, 255, 0.5);
  border-right: 2px solid rgba(255, 255, 255, 0.5);
  z-index: 3;
}
.clip__inner {
  color: white;
  width: 100%;
  height: var(--timeline-clip-height);
  position: relative;
  overflow: hidden;
  border-radius: 3px;
}
.clip__inner::after {
  --fade-color: var(--black);

  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 75px;
  height: 100%;
  background: linear-gradient(to right, transparent 10%, var(--fade-color) 75%);
  z-index: 1;
}
.clip__header {
  position: absolute;
  top: 2px;
  left: 2px;
  display: flex;
  align-items: center;
  gap: 2px;
  font-size: 11px;
  padding: 3px;
  border-radius: 4px;
  color: white;
  z-index: 2;
  pointer-events: none;
}
.clip__icon {
  padding: 3px;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.3);
  display: inline-block;
}
.clip__icon svg {
  display: block;
}
.clip__icon .spinner-border {
  display: block;
  --bs-spinner-width: 0.75rem;
  --bs-spinner-height: 0.75rem;
  --bs-spinner-border-width: 0.2em;
}
.clip__type {
  margin-left: 4px;
  padding-right: 4px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 0.05rem;
}
.clip__content-raw {
  padding-left: 4px;
  margin-left: 4px;
}
.clip__content-raw,
.clip__content-raw p {
  white-space: nowrap;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1;
}
.clip__content-raw p {
  margin: 0;
  padding: 0;
}
.clip__more {
  position: absolute;
  top: -7px;
  right: 5px;
  color: var(--white);
  display: block;
  line-height: 1;
  z-index: 3;
}
.clip__more:hover,
.clip__more:focus-visible {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  color: var(--white) !important;
}
.clip__info {
  position: absolute;
  bottom: 3px;
  right: 6px;
  z-index: 2;
  color: var(--white);
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  gap: 6px;
}
.clip__info span {
  line-height: 1;
}
.clip__sprite {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
}

.clip.active {
  z-index: var(--z-index-timeline-clip-active);
  box-shadow: 0 0 0 2px var(--white), 0 0 0 5px var(--primary-dark);
}
.clip.error {
  z-index: var(--z-index-timeline-clip-active);
  box-shadow: 0 0 0 2px var(--white), 0 0 0 5px orange;
}
.clip.dragging {
  z-index: var(--z-index-timeline-clip-dragging);
  transform: scale(1.03);
}

.clip.text,
.clip.title,
.clip.text .clip__header,
.clip.title .clip__header {
  background-color: var(--asset-color-text);
}
.clip.text .clip__inner::after,
.clip.title .clip__inner::after {
  --fade-color: var(--asset-color-text);
}
.clip.audio,
.clip.audio .clip__header {
  background-color: var(--asset-color-audio);
}
.clip.audio .clip__inner::after {
  --fade-color: var(--asset-color-audio);
}
.clip.image,
.clip.image .clip__header {
  background-color: var(--asset-color-image);
}
.clip.image .clip__sprite {
  --image-url: '';
  width: 100%;
  background-image: var(--image-url);
  background-size: contain;
  background-repeat: repeat-x;
}
.clip.image .clip__inner.loading::after {
  --fade-color: transparent;
}
.clip.video,
.clip.video .clip__header {
  background-color: var(--asset-color-video);
}
.clip.video .clip__inner.loading::after {
  --fade-color: transparent;
}
.clip.video .clip__sprite {
  display: flex;
}
.clip.video .clip__sprite img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.clip.overlay {
  background-color: var(--asset-color-overlay);
}
.clip.overlay .clip__header {
  background-color: var(--black);
}
.clip.overlay .clip__inner.loading::after {
  --fade-color: transparent;
}
.clip.overlay .clip__sprite {
  display: flex;
  width: 100%;
}
.clip.overlay .clip__sprite img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.clip.overlay .sprite-fill {
  background-color: var(--black);
  flex-grow: 1;
}

.clip--hidden-on-canvas {
  opacity: 0.5;
}

.clip.mask {
  background-color: var(--asset-color-mask);
}
.clip.mask .clip__header {
  top: -4px;
  transform: scale(0.7);
  transform-origin: 0;
  background-color: var(--black);
}
.clip.mask .clip__inner {
  height: 18px;
}

.clip.mask .clip__more {
  top: -4px;
}
.clip.mask .clip__info {
  bottom: 3px;
  right: 30px;
}

@container clip-container (max-width: 25px) {
  .clip__more {
    display: none;
  }
}
@container clip-container (max-width: 60px) {
  .clip__inner {
    visibility: hidden;
  }
}
@container clip-container (max-width: 140px) {
  .clip__info {
    display: none;
  }
}
@container clip-container (max-width: 190px) {
  .clip__type {
    display: none;
  }
}
