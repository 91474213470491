.preset-button {
  width: 160px;
  height: 160px;
  border-width: 3px;
}
.preset-button__text {
  margin-top: 1rem;
}
.preset-button.video.selected {
  border-color: var(--asset-color-video);
}
.preset-button.image.selected {
  border-color: var(--asset-color-image);
}
.preset-button.audio.selected {
  border-color: var(--asset-color-audio);
}
