:root {
  --white: #ffffff;
  --black: var(--black);

  --gray-50: #fafafa;
  --gray-100: #f4f4f5;
  --gray-200: #e4e4e7;
  --gray-300: #d4d4d8;
  --gray-400: #a1a1aa;
  --gray-500: #71717a;
  --gray-600: #52525b;
  --gray-700: #3f3f46;
  --gray-800: #27272a;
  --gray-900: #18181b;
  --gray-950: #09090b;

  --slate-50: #f8fafc;
  --slate-100: #f1f5f9;
  --slate-200: #e2e8f0;
  --slate-300: #cbd5e1;
  --slate-400: #94a3b8;
  --slate-500: #64748b;
  --slate-600: #475569;
  --slate-700: #334155;
  --slate-800: #1e293b;
  --slate-900: #0f172a;
  --slate-950: #020617;

  --primary: #25d3d0;
  --primary-dark: #24c4c1;
  --body-color: var(--slate-800);
  --background-color: #f9fbfb;

  --secondary-colors-blue-light: #40dde7;
  --secondary-colors-blue: #7abeef;
  --secondary-colors-lilac: #b3a0f8;
  --secondary-colors-purple: #e585ff;

  --red-200: #f8d7da;
  --red-300: #f5c6cb;
  --red-500: #721c24;

  /* --success-color: #9bf196; */
  --success-color: #7fd325;
  --warning-color: #f9f871;
  --danger-color: var(--red-500);

  --bs-blue: var(--secondary-colors-blue);
  --bs-indigo: #4518d4;
  --bs-purple: #623cdd;
  --bs-pink: #fe5559;
  --bs-red: #fe2d32;
  --bs-orange: #ffb82d;
  --bs-yellow: #ffc107;
  --bs-green: #31c445;
  --bs-teal: var(--primary);
  --bs-cyan: #0dcaf0;

  --bs-primary: var(--primary);
  --bs-secondary: #6c757d;
  --bs-success: var(--bs-green);
  --bs-info: var(--bs-blue);
  --bs-warning: var(--bs-orange);
  --bs-danger: var(--bs-red);
  --bs-light: var(--slate-200);
  --bs-dark: #212529;

  --bs-primary-rgb: 37, 211, 208;
  --bs-success-rgb: 49, 196, 69;
  --bs-info-rgb: 122, 190, 239;
  --bs-warning-rgb: 255, 184, 45;
  --bs-danger-rgb: 254, 45, 50;
  --bs-light-rgb: 244, 244, 244;

  --asset-color-audio: var(--secondary-colors-blue-light);
  --asset-color-text: var(--secondary-colors-blue);
  --asset-color-image: var(--secondary-colors-lilac);
  --asset-color-video: var(--secondary-colors-purple);
  --asset-color-overlay: var(--gray-100);
  --asset-color-mask: var(--slate-700);

  --shadow-depth-1: 0 1px 2px 0 rgb(5 5 5 / 0.05);
  --shadow-depth-2: 0 1px 3px 0 rgb(5 5 5 / 0.1), 0 1px 2px -1px rgb(5 5 5 / 0.1);
  --shadow-depth-3: 0 4px 6px -1px rgb(5 5 5 / 0.1), 0 2px 4px -2px rgb(5 5 5 / 0.1);
  --shadow-depth-4: 0 10px 15px -3px rgb(5 5 5 / 0.1), 0 4px 6px -4px rgb(5 5 5 / 0.1);
  --shadow-depth-5: 0 20px 25px -5px rgb(5 5 5 / 0.1), 0 8px 10px -6px rgb(5 5 5 / 0.1);
  --shadow-depth-6: 0 25px 50px -12px rgb(5 5 5 / 0.25);

  --border-radius-base: 8px;

  --disabled-opacity: 0.75;

  /* fonts */
  --font-family-base: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --font-family-body: 'Nunito Sans', var(--font-family-base);
  --font-family-headings: 'Kanit', var(--font-family-base);

  --timeline-header-height: 2.5rem;
  --timeline-track-header-width: 286px;
  --timeline-track-left: 292px;

  --timeline-track-height: 55px;
  --timeline-subtrack-mask-height: 20px;
  --timeline-clip-height: 35px;

  /* bootstrap overrides */
  --bs-btn-bg: var(--primary);
  --bs-btn-hover-bg: var(--primary-dark);
  --bs-btn-border-color: var(--primary);
  --bs-btn-hover-border-color: var(--primary-dark);
  --bs-btn-active-bg: var(--primary-dark);
  --bs-btn-active-border-color: var(--primary-dark);

  --bs-body-color: var(--body-color);
  --bs-link-color: var(--primary);
  --bs-link-hover-color: var(--primary-dark);

  --bs-btn-padding-x: 0.7rem;
  --bs-btn-padding-y: 0.2rem;

  --bs-border-color-translucent: rgb(0 0 0 / 5%);

  /* z indexes */
  --z-index-timeline-header: 150;
  --z-index-timeline-header-playhead: 151;
  --z-index-timeline-header-timecode: 155;

  --z-index-timeline-clip: 100;
  --z-index-timeline-clip-active: 101;
  --z-index-timeline-playhead-line: 102;
  --z-index-timeline-track-header: 103;
  --z-index-timeline-track-dropzone-active: 104;
  --z-index-timeline-clip-dragging: 105;

  --z-index-timeline-track-clone: 200;

  --z-index-json-view: 500;
}

html,
body {
  height: 100%;
}

body {
  background-color: var(--background-color);
  font-family: var(--font-family-body);
}

.h1,
h1,
.h2,
h2,
.h3,
h3 {
  color: var(--black);
  line-height: 1.4;
}
.h1,
h1 {
  font-family: var(--font-family-headings);
  font-weight: 700;
}
.h2,
h2 {
  font-weight: 600;
}
.h3,
h3 {
  font-weight: 400;
}
.h4,
h4 {
  font-weight: 300;
}
.h5,
h5 {
  font-weight: 300;
}
.h6,
h6 {
  font-weight: 400;
}

p code {
  padding: 0.2rem;
  background: var(--gray-50);
  border-radius: 4px;
}

.form-select,
.form-control {
  font-size: 0.8rem;
  font-weight: 600;
  border-radius: 4px;
  border: 1px solid var(--slate-200);
}
.form-select:not(:disabled):hover,
.form-control:not(:disabled):hover {
  border-color: var(--slate-400);
}
.form-select:disabled {
  background-color: var(--slate-100);
  color: var(--slate-500);
}

.form-control::placeholder {
  font-weight: 400;
}

.form-control.is-invalid:focus {
  box-shadow: none;
}

a {
  color: var(--primary);
}

a:hover,
.navbar-nav .nav-link.active:hover {
  color: var(--primary-dark);
  text-decoration: underline;
}

label:not(.form-checkbox),
.form-label {
  color: var(--slate-500);
  margin-bottom: 0.25rem;
  font-size: 0.7rem;
  text-transform: uppercase;
}
.form-label.link {
  color: var(--primary-dark);
}
.form-checkbox {
  display: flex;
  color: var(--slate-400);
  text-transform: uppercase;
  font-size: 0.8rem;
}
.form-checkbox input {
  margin-right: 0.5rem;
}

.btn {
  --bs-btn-padding-y: 0.225rem;
  --bs-btn-font-weight: 800;
}

.btn {
  --bs-btn-disabled-color: var(--slate-500);
  --bs-btn-disabled-bg: var(--slate-100);
  --bs-btn-disabled-border-color: var(--slate-200);
  --bs-btn-disabled-opacity: var(--disabled-opacity);
}

.btn-primary,
.btn-primary:hover,
.btn-primary:not(:disabled):not(.disabled).active {
  background-color: var(--primary);
  border-color: var(--primary);
}

.btn-primary:hover {
  background-color: var(--primary-dark);
}

.btn-primary:active:focus,
.btn-primary.focus,
.btn-primary:focus {
  color: var(--white) !important;
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.btn-light {
  color: var(--slate-800);
  background-color: var(--white);
  border-color: var(--slate-200);
}
.btn-light:hover {
  background-color: var(--white);
  border-color: var(--slate-400);
}

.btn-outline-secondary {
  --bs-btn-color: var(--slate-800);
  --bs-btn-border-color: var(--gray-400);
  --bs-btn-hover-color: var(--white);
  --bs-btn-hover-bg: var(--slate-400);
  --bs-btn-hover-border-color: var(--slate-400);
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: var(--white);
  --bs-btn-active-bg: var(--slate-400);
  --bs-btn-active-border-color: var(--slate-400);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--slate-400);
  --bs-btn-disabled-bg: var(--slate-100);
  --bs-btn-disabled-border-color: var(--slate-100);
  --bs-gradient: none;
}

.btn.fixed,
.btn.fixed:hover,
.btn.fixed:not(:disabled):not(.disabled).active {
  min-width: 110px;
}

button[type='submit']:disabled {
  cursor: not-allowed;
  color: var(--slate-500);
  background: var(--slate-100);
  border-color: var(--slate-200);
  opacity: var(--disabled-opacity);
}

a.btn {
  text-decoration: none;
}

button.unstyled {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

video {
  max-width: 100%;
  max-height: 100%;
  height: auto;
}

audio {
  width: 100%;
}

.text-primary {
  color: var(--primary) !important;
}

.text-secondary {
  color: var(--gray-500) !important;
}

.bg-primary {
  background-color: var(--primary) !important;
}

.super {
  vertical-align: super;
}

.accordion-item {
  background: none;
  border: 1px solid var(--slate-100);
}

.accordion-button {
  padding: 1.25rem 1rem;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-button .row {
  width: 100%;
}

.accordion-row-header {
  font-weight: 700;
  margin-right: 1.25rem;
  padding: 1rem;
}

.accordion-button:not(.collapsed) {
  color: var(--slate-600);
  background-color: var(--gray-100);
}

.accordion-button::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e') !important;
}

.dropdown-header {
  font-weight: bold;
  text-align: left;
  color: var(--slate-400);
  margin-bottom: 0.25rem;
  font-size: 0.7rem;
  text-transform: uppercase;
}
.dropdown-header .form-label {
  margin-bottom: 0;
}

a.dropdown-item:hover {
  text-decoration: none;
}

.card {
  --bs-card-border-width: 0;
  box-shadow: var(--shadow-depth-1);
}
.card .card {
  box-shadow: var(--shadow-depth-2);
}

.card .card-new-tab {
  position: absolute;
  top: 5px;
  z-index: 1;
  right: 5px;
  opacity: 0.6;
  padding: 6px 8px;
}

.card-img {
  object-fit: cover;
}

.StripeElement--focus,
.form-control:focus,
.form-select:focus,
.form-check-input:focus {
  border-color: var(--primary-dark);
  box-shadow: 0 0 0 0.25rem rgb(37 211 208 / 20%);
}

.form-control:disabled {
  color: var(--slate-500);
  background-color: var(--slate-100);
  opacity: var(--disabled-opacity);
}

.form-check-input:checked {
  background-color: var(--primary);
  border-color: var(--primary);
}

.link-element-arrow {
  width: 16px;
  height: 16px;
  margin-left: 0.5rem;
}

.accordion-row-header > div {
  white-space: nowrap;
  font-size: 14px;
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1140px;
  }
}

.link {
  cursor: pointer;
}

/* Webkit */
input[type='range'] {
  appearance: none;
  width: 100%;
  border-radius: 8px;
  height: 7px;
  background-color: var(--slate-100);
}
input[type='range']::-webkit-slider-thumb {
  appearance: none;
  background-color: white;
  border: 3px solid var(--primary);
  width: 16px;
  height: 16px;
  border-radius: 10px;
  cursor: pointer;
}
input[type='range']::-webkit-slider-thumb:hover {
  box-shadow: var(--shadow-depth-2);
}

/* Mozilla */
input[type='range']::-moz-range-track {
  background-color: var(--slate-100);
  border-radius: 8px;
  height: 7px;
}
input[type='range']::-moz-range-thumb {
  background-color: var(--white);
  border: 3px solid var(--primary);
  width: 10px;
  height: 10px;
  border-radius: 100%;
  cursor: pointer;
}
input[type='range']::-moz-range-thumb:hover {
  box-shadow: var(--shadow-depth-2);
}

.hide-scroll {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
/* WebKit */
.hide-scroll::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.json-view {
  position: absolute;
  top: 48px;
  left: 0;
  right: 0;
  bottom: 37px;
  z-index: var(--z-index-json-view);
  overflow: auto;
}
.json-view > div,
.json-view .cm-editor {
  height: 100%;
}

.json-debug {
  position: absolute;
  left: 10px;
  top: 70px;
  bottom: 420px;
  right: 380px;
  z-index: 99;
  overflow: auto;
  border-radius: 8px;
  opacity: 0.85;
}

.text-link {
  color: var(--primary);
  text-decoration: underline;
  cursor: pointer;
}
button.link,
.text-link {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
}

.offcanvas-title.h5 {
  font-weight: 800;
}

.small,
small {
  font-size: 0.7rem;
}

.offcanvas.offcanvas-end {
  border: none;
}
.offcanvas-backdrop {
  background-color: var(--slate-700);
}
.offcanvas-backdrop.show {
  opacity: 0.8;
}

.input-group-text {
  font-size: 0.8rem;
  background-color: var(--white);
  border-color: var(--slate-200);
}

.emptystate {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100%;
}
.emptystate__icon {
  opacity: 0.3;
  margin-bottom: 1rem;
  color: var(--slate-500);
}
.emptystate__text {
  padding: 0 1rem;
}

.emptystate__text h5,
.emptystate__text p {
  color: var(--slate-500);
}
.emptystate__text p {
  font-size: 0.9rem;
}
.emptystate__text h5 {
  font-weight: 700;
}

.dropdown-menu,
.popover {
  box-shadow: var(--shadow-depth-3);
  z-index: 1039;
  max-width: unset;
  border-radius: 8px;
  border: 0;
}

.toast {
  --bs-toast-max-width: 640px;
  --bs-toast-color: var(--black);
  --bs-toast-bg: var(--white);
  --bs-toast-border-width: 0;
  --bs-toast-border-color: transparent;
  --bs-toast-border-radius: 0.375rem;
  --bs-toast-header-color: var(--black);
  --bs-toast-header-bg: transparent;
  --bs-toast-header-border-color: rgba(0, 0, 0, 0.05);
}
.toast-error .toast {
  border: 2px solid var(--bs-danger);
}
.toast-error .toast-header {
  font-size: 1rem;
  font-weight: 600;
}
.toast-error .toast-header svg {
  fill: var(--bs-danger);
}
.toast-warning .toast {
  border: 2px solid var(--bs-warning);
}
.toast-warning .toast-header svg {
  fill: var(--bs-warning);
}

.popover-controls h2 {
  font-weight: 800;
  font-size: 1rem;
  text-transform: uppercase;
}
.popover-controls__inner {
  padding: 4px 8px;
  border-radius: 8px;
  background: var(--gray-50);
}
.popover-controls__row {
  display: flex;
  gap: 6px;
}
.popover-controls__row [type='text'],
.popover-controls__row [type='number'],
.popover-controls__row select {
  font-family: 'Courier New', Courier, monospace;
}
.popover-controls__row .btn {
  margin-top: 24px;
  height: 33px;
}

.input-error {
  font-size: 0.8rem;
  color: var(--bs-red);
}
.text-error {
  color: var(--bs-red);
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 2rem !important;
  --bs-modal-margin: 0.5rem;
  --bs-modal-bg: var(--white);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 1rem;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(1rem - 1px);
  --bs-modal-header-padding-x: 2rem;
  --bs-modal-header-padding-y: 2rem;
  --bs-modal-header-padding: 2rem 2rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
}
.modal-header,
.modal-footer {
  border: 0;
}

.modal-header + .modal-body,
.modal-body + .modal-footer {
  padding-top: 0;
}

.asset-modal .modal-content {
  padding: 0.7rem;
}

.asset-modal .modal-body video,
.asset-modal .modal-body img {
  max-height: 70vh;
}

.asset-modal .modal-footer {
  display: block;
}

.badge.bg-primary.bg-subtle {
  background-color: #f2f8f8 !important;
  color: #8fb7b7;
}

.centered {
  text-align: center;
}
.font-mono {
  font-family: 'Courier New', Courier, monospace;
}
.text-small {
  font-size: 0.9rem;
}
.opacity-50 {
  opacity: 0.5;
}

.helper-text {
  margin-top: 0.4rem;
  margin-bottom: 0;
  font-size: 0.8rem;
  color: var(--gray-700);
}

.stretched-link {
  cursor: pointer;
}

/* custom scroll bar overrides */
.ScrollbarsCustom:hover .ScrollbarsCustom-Track {
  opacity: 1;
}
.ScrollbarsCustom.trackYVisible .ScrollbarsCustom-Scroller {
  margin-right: -21px !important;
  padding-right: 6px !important;
}
.ScrollbarsCustom-Wrapper {
  inset: 0px 0px 0px 0px !important;
}
.ScrollbarsCustom-Track {
  width: 4px !important;
  right: -10px !important;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
.ScrollbarsCustom-Thumb {
  background: rgba(0, 0, 0, 0.3) !important;
}
.video-capture {
  position: absolute;
  left: -9999px;
  top: -9999px;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-container.sm {
  gap: 1rem;
}

.form-container hr {
  margin: 0.25rem 0;
  color: var(--slate-300);
}

/* React Select */

/* .react-select-container {} */
/* .react-select__control--is-focused {}
.react-select__control--menu-is-open {}
.react-select__value-container {}
.react-select__value-container--has-value {}
.react-select__single-value {}
.react-select__input-container {}
.react-select__input {}
.react-select__indicators {}
.react-select__indicator-separator {}
.react-select__indicator {}
.react-select__dropdown-indicator {} */

.react-select__control {
  min-height: unset !important;
}
.react-select__menu {
  left: 0;
  margin-top: 3px;
  border: 0;
  box-shadow: var(--shadow-depth-3);
  z-index: 1039;
  max-width: unset;
  border-radius: 8px;
  background: var(--white);
}
/* .react-select__menu-list {
} */
.react-select__menu-notice {
  padding: 0.5rem;
  font-size: 0.8rem;
}
.react-select__menu-notice--no-options {
  color: var(--gray-700);
}

.react-select__inputs .react-select__option {
  font-family: 'Courier New', Courier, monospace;
}

/* .react-select__group {
} */
.react-select__group-heading {
  color: var(--gray-800);
  font-size: 0.6rem;
  font-family: inherit;
  /* font-weight: normal; */
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 0.75rem 0.5rem;
}
.react-select__option {
  padding: 0.5rem;
}

/* .react-select__option--is-focused {
} */
.react-select__option:hover {
  background-color: var(--gray-100);
}
.react-select__option--is-selected,
.react-select__option--is-selected:hover {
  color: var(--white);
  background-color: var(--primary);
}
.react-select__option--is-disabled,
.react-select__option--is-disabled:hover {
  color: var(--slate-500);
  background-color: transparent;
  cursor: not-allowed;
}
.react-select--is-disabled {
  opacity: 1;
  background-color: var(--slate-100);
}

.beta-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
}
.beta-tag span {
  background: linear-gradient(to bottom right, #02fede 0%, #e784ff 100%);
  background-size: 400% 400%;
  animation: animateGradient 4s ease infinite;
  border-radius: 4px;
  color: var(--white);
  display: inline-block;
  font-size: 0.8rem;
  font-weight: 800;
  line-height: 1;
  padding: 4px 5px;
  text-transform: uppercase;
}
.popover-beta-tag .popover-body {
  max-width: 460px;
  text-align: center;
  padding: 2rem;
}
.popover-beta-tag h4 {
  /* font-size: 1.4rem; */
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.popover-beta-tag .not-supported {
  background-color: var(--slate-50);
  padding: 1rem;
  border-radius: 8px;
}

.keys-table {
  --bs-table-border-color: var(--gray-100);
}

@keyframes animateGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.button-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  cursor: pointer;
  color: var(--white);
  background: var(--primary);
  border: 0;
}
.button-arrow:hover {
  background: var(--primary-dark);
}
.button-arrow::after {
  border-bottom: 0;
  border-left: 0.3em solid #0000;
  border-right: 0.3em solid #0000;
  border-top: 0.3em solid;
  content: '';
  display: inline-block;
  vertical-align: 0.255em;
}

.popover-preview-settings {
  margin-top: 10px;
  position: fixed !important;
}
.popover-preview-settings .popover-body {
  max-width: 460px;
}

.tag {
  display: inline-block;
  letter-spacing: 0.1rem;
  background-color: var(--gray-100);
  color: var(--gray-700);
  font-size: 0.6rem;
  font-weight: 600;
  padding: 0.2rem 0.3rem;
  border-radius: 2px;
  text-transform: uppercase;
}

.uppercase-heading {
  color: var(--gray-800);
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: 800;
}

.experience-form {
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
}
.experience-form p:last-child {
  margin-bottom: 0;
}
.experience-emoji {
  font-size: 1.5rem;
}
.experience-emoji__label {
  position: relative;
  cursor: pointer;
  width: 3rem;
}
.experience-emoji__label span,
.experience-emoji__label div {
  position: relative;
  z-index: 1;
}
.experience-emoji__label span {
  color: var(--gray-600);
  font-weight: 600;
  margin-top: 0.5rem;
  text-transform: none;
}
.experience-emoji__label--active span {
  color: var(--bs-secondary);
}
.experience-emoji__label--active::after {
  content: '';
  /* floating background */
  position: absolute;
  top: -0.5rem;
  left: -0.5rem;
  right: -0.5rem;
  bottom: -0.5rem;
  border-radius: 100%;
  background-color: var(--bs-secondary);
  opacity: 0.2;
  z-index: 0;
}
.experience-button {
  font-size: 0.9rem;
  font-weight: 600;
}
.experience-nps__label {
  font-size: 0.8rem;
  font-weight: 600;
  color: var(--gray-600);
  margin-top: 0.2rem;
}

.required {
  color: var(--bs-red);
}

.alertbox {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  border-radius: 8px;
  background-color: var(--white);
  border: 2px solid var(--gray-400);
}
.alertbox.info {
  color: var(--secondary-colors-blue);
  border: 2px solid var(--secondary-colors-blue);
}
.alertbox.error {
  color: var(--bs-red);
  border: 2px solid var(--bs-red);
}
.alertbox.warning {
  color: var(--bs-orange);
  border: 2px solid var(--bs-orange);
}
.alertbox h6 {
  margin-bottom: 0;
}
.alertbox__icon {
  margin: 0;
  line-height: 0;
}
.alertbox__content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
}

img.asset-details-preview,
video.asset-details-preview {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 300px;
  object-fit: contain;
}
audio.asset-details-preview {
  margin-top: 20%;
  margin-bottom: 20%;
}
.asset-details-preview__placeholder {
  width: 100%;
  height: 300px;
  background-color: var(--gray-100);
  margin-bottom: 1rem;
}

.alert {
  font-weight: bold;
}
.alert-success {
  --bs-alert-color: #0c5460;
  --bs-alert-bg: #d1e7e9;
  --bs-alert-border-color: #d1e7e9;
}
.alert-warning {
  --bs-alert-color: #886807;
  --bs-alert-bg: #fff3cd;
  --bs-alert-border-color: #fff3cd;
}
.alert-danger {
  --bs-alert-color: #973f47;
  --bs-alert-bg: #f8d7da;
  --bs-alert-border-color: #f8d7da;
}
.alert-info {
  --bs-alert-color: #097f92;
  --bs-alert-bg: #e3f4f7;
  --bs-alert-border-color: #e3f4f7;
}

/* UTILS */

.font-normal {
  font-weight: 400;
}
.font-bold {
  font-weight: 700;
}

.text-2xl {
  font-size: 1.8rem;
}
.text-xl {
  font-size: 1.5rem;
}
.text-lg {
  font-size: 1.2rem;
}
.text-md {
  font-size: 1.1rem;
}
.text-base {
  font-size: 1rem;
}
.text-sm {
  font-size: 0.8rem;
}
.text-xs {
  font-size: 0.7rem;
}

.w-full {
  width: 100%;
}
.h-full {
  height: 100%;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}
