.studio.editor .navbar {
  display: none;
}
.studio,
.editor {
  height: 100vh;
}
/* .editor {
  background-repeat: repeat;
  background-size: 30px 30px;
} */
.studio .editor {
  overflow: hidden;
}
.editor__main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.editor-panel {
  padding: 0.4rem;
  padding-right: 1.2rem;
  margin: 0;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.editor-panel h3,
.editor-panel h4 {
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 1rem;
  text-transform: capitalize;
  padding-top: 0;
  padding-bottom: 0;
}

.editor-panel h4 {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.editor-panel .card {
  margin-bottom: 0.6rem;
}
.editor-panel .card:last-child {
  margin-bottom: 0;
}
.editor-panel .card-body {
  padding: 0.6rem;
}
.editor-panel .clip-card h4 {
  border-top: 1px solid var(--gray-400);
  padding-top: 0.8rem;
}

.editor-panel__container {
  width: 100%;
  padding: 0.6rem;
  overflow: hidden;
}

.editor-panel.placeholders {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.editor-thumbnail-container {
  border-radius: 8px;
  overflow: hidden;
}

.editor-thumbnail {
  height: 242px;
  width: auto;
}

.editor-thumbnail img,
.editor-thumbnail video {
  display: block;
  height: 242px;
  width: auto;
}

.feedback-button {
  text-decoration: none;
  color: inherit !important;
  white-space: nowrap;
}

.settings {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.settings > div {
  padding: 1rem;
  background-color: var(--gray-50);
  border-radius: 8px;
}

.settings h6 {
  font-weight: 800;
  font-size: 0.8rem;
  text-transform: uppercase;
}
