.timeline-header {
  height: var(--timeline-header-height);
  width: calc(500% + 286px);
  background: white;
  position: sticky;
  top: 0;
  border-bottom: 1px solid var(--gray-100);
  z-index: var(--z-index-timeline-header);
}

.timeline-header::after {
  content: '';
  position: absolute;
  top: 0;
  left: 4px;
  height: 100%;
  width: 100%;
  display: block;
  background: url(@assets/img/lines.png) repeat-x;
  background-size: 18px 35px;
}

.timeline-header__timecode-wrapper {
  position: sticky;
  width: var(--timeline-track-header-width);
  height: 0;
  top: 0;
  left: 0;
  z-index: var(--z-index-timeline-header-timecode);
}

.timeline-header__timecode {
  position: sticky;
  top: 0;
  left: 0;
  height: var(--timeline-header-height);
  width: 100%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 800;
  padding: 0 0.7rem;
  color: var(--black);
  border-bottom: 1px solid var(--gray-100);
  border-right: 1px solid var(--gray-100);
}
