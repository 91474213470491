.timeline-playhead-line {
  position: absolute;
  top: 0;
  height: 100%;
  width: 3px;
  background-color: red;
  transition: transform 0ms linear;
  will-change: transform;
  cursor: pointer;
  z-index: var(--z-index-timeline-playhead-line);
}
