.scrub {
  z-index: 10;
  position: relative;
  height: 30px;
}
.scrub input {
  opacity: 0;
  height: 40px;
}
.scrub input[type='range']::-moz-range-track {
  height: 40px;
}
