.scale {
  display: flex;
  align-items: center;
  width: 175px;
}
.scale__icon {
  margin: 0 10px;
}
.scale__px {
  margin-right: 10px;
  width: 40px;
  font-weight: 600;
  white-space: nowrap;
  padding-right: 5px;
  cursor: help;
}
