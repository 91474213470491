.template-configuration .modal-dialog {
  width: 568px;
}
.template-configuration .modal-header {
  text-align: center;
}
.template-configuration .modal-title {
  width: 100%;
  font-size: 1rem;
  font-weight: bold;
}
.template-configuration .modal-body {
  display: flex;
  flex-direction: column;
  row-gap: 1.4rem;
}
.template-configuration .output-types {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
.template-configuration .advanced-toggle {
  position: relative;
  overflow: hidden;
}
.template-configuration .advanced-toggle span {
  position: relative;
  z-index: 10;
  background-color: var(--white);
  padding-left: 10px;
  padding-right: 10px;
  font-size: 0.8rem;
  font-weight: bold;
  text-transform: uppercase;
}
.template-configuration .advanced-toggle::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: var(--slate-100);
  top: 49%;
  left: 0;
}
